import React from 'react'
import DesignSystem from '../../components/DesignSystem'

import Islands from './index'

const BigIsland = () => {
  return (
    <Islands activeIsland="big-island">
      <DesignSystem.H1>Island of Hawai'i (Big Island)</DesignSystem.H1>
      <DesignSystem.P>blurb</DesignSystem.P>
    </Islands>
  )
}

export default BigIsland
